
import Vue from "vue";

import AppDrawer from "@/components/AppDrawer.vue";
import AppMenuItens from "@/components/AppMenuItens.vue";
import UserMenu from "@/components/UserMenu.vue";
import ApiMobileService from "@/services/ApiMobile";
import moment from "moment";
import Auth from "@/lib/Auth";
import router from "../router";
import { Overlay } from "@/lib/Overlay";
export default Vue.extend({
  name: "AppBar",
  data: () => ({
    AppNavDrawer: null,
    showMenuTransfPenendetes: false,
    MenuTopo: false,
    AppNavNotificacoes: false,
    countNotUnread: 0,
    notificacoes: [],
    countNotificacoes: 0,
    lang: navigator.language,
    moment: moment,
    permissoes: {} as any,
  }),
  components: {
    // AppDrawer,
    //AppMenuItens,
    UserMenu,
  },
  created() {
    this.permissoes = Auth.getUserPermissoes();
    //console.log(this.permissoes);

    const us = Auth.getUser();
    this.showMenuTransfPenendetes = us.data.apiStatus;

    if (this.$router.currentRoute.fullPath == "/") {
      //this.getUreadNotifications();
    }
    if (
      sessionStorage.unreadNotifications == undefined ||
      sessionStorage.unreadNotifications == null
    ) {
      this.countNotUnread = 0;
    } else {
      this.countNotUnread = sessionStorage.unreadNotifications;
    }
  },
  methods: {
    FecharMenu() {
      this.MenuTopo = false;
    },
    logout() {
      Auth.logout();
    },
    getUreadNotifications() {
      ApiMobileService.get("v1/notification/count/unread")
        .then((response) => {
          sessionStorage.setItem(
            "unreadNotifications",
            response.data.count_unread.toString()
          );
          this.countNotUnread = sessionStorage.unreadNotifications;
        })
        .catch((err) => {
          // ...
        })
        .finally(() => {
          // Overlay.hide();
        });
    },
    isDemissibleNotification(item: string) {
      if (item === "all") {
        return false;
      } else {
        return true;
      }
    },
    getNotifications() {
      Overlay.show();
      ApiMobileService.get("v1/notification?limit=10&page=0")
        .then((response) => {
          this.countNotUnread = response.data.count_unread;
          this.notificacoes = response.data.rows;
          this.countNotificacoes = response.data.rows.length;

          this.AppNavNotificacoes = true;
          this.makeAllNotificationRead();
        })
        .catch((err) => {
          // ...
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    oncloseNotification(item: any) {
      ApiMobileService.delete("v1/notification/" + item._id).then(
        (response) => {
          this.countNotUnread = 0;
        }
      );
    },
    makeAllNotificationRead() {
      ApiMobileService.put("v1/notification/mark-all-as-read").then(
        (response) => {
          this.countNotUnread = 0;
        }
      );
    },
    goLink(url: string, origem: string) {
      console.log(this.$router.currentRoute.fullPath);
      console.log(url);

      if (this.$router.currentRoute.fullPath != url) {
        router.push("/movimento");
      }
    },
  },
});
