
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "Dashboard",

  data() {
    return {
      ModuloInfo: [],
      loadingData: true,
      dadosUsuario: {} as any,
      mfaEnabled: true,
      lang: navigator.language,
      errors: [],
      rand: 1,
      moment: moment,
      isMobile: false,
      permissoes: {} as any,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.rand = new Date().getTime();
    this.dadosUsuario = Auth.getUserData();
    this.permissoes = Auth.getUserPermissoes();
    this.get2FAStatus();

  
  },
  
 
  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    get2FAStatus() {
      Overlay.show();

      Api.get("/v2/colaboradores/security/2fa/status")
        .then((response) => {
          this.mfaEnabled = response.data.body.totp;
        })

        .finally(() => {
          this.loadingData = false;
          Overlay.hide();
        });
    },    
    
  },
});
