
import Auth from "@/lib/Auth";
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import { TableOptions } from "@/models/TableOptions";
import html2pdf from 'html2pdf.js';

interface ComprovantePixRecebido {
  valorFormatado: string;
  dataEfetivada: string;
  origem: {
    name: string;
    taxId: string;
    bank: string;
    branch: string;
    accountType: string;
  };
  destino: {
    name: string;
    taxId: string;
  };
  endToEndId: string;
}

export interface RelatorioPix {
  data?: string;
  transactionId?: string;
  valorOriginal?: string;
  valorOriginalFormatado?: string;
  valorPago?: string;
  valorPagoFormatado?: string;
  status?: string;
  dataPagamento?: number;
  NomePagador?: string;
}

export default Vue.extend({
  name: "RelatorioPix",

  data() {
    return {
      dadosComprovantePixRecebido: {} as ComprovantePixRecebido,
      btnLoadingResent: false,
      dialogWebhooks: false,
      hasLoadedData: false,
      dialogComprovantePixRecebido: false,
      //dadosComprovantePixRecebido: [],
      menuInitialDate: false,
      menuFinalDate: false,
      menuInitialDateMobile: false,
      ModuloInfo: [],
      loadingData: true,
      search: "",
      loadingDesserts: false,
      lang: navigator.language,
      stats: [],
      errors: [] as any,
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {} as TableOptions,
      selectedEntryStatusPix: { id: "TODOS", descricao: "Todos" },
      queryField: [
        { id: "transactionE2E", descricao: "Id Pix (E2E)" },
        { id: "transactionId", descricao: "ID" },
        { id: "externalId", descricao: "ID Externo" },
        { id: "payerDocument", descricao: "Documento Pagador" }
      ],
      selectedEntryQueryField: { id: "transactionE2E", descricao: "Id Pix (E2E)" },
      statusPix: [
        { id: "TODOS", descricao: "Todos" },
        { id: "APROVADO", descricao: "Aprovados" },
        { id: "PENDENTE", descricao: "Pendente" },
        { id: "DEVOLVIDO", descricao: "Devolvidos" },
        { id: "EXPIRADO", descricao: "Expirados" },
        { id: "INFRACTION", descricao: "Infração" },

      ],
      translatedQueryField: [] as { id: string; descricao: string; }[],
      headerTranslations: {},
      translatedStatusPix: [] as { id: string; descricao: string; }[],
      headers: [
        {
          text: "",
          value: "status",
          sortable: false
        },
        {
          text: "components.RelatorioPix.template.DataTable.Issuance",
          value: "data",
          sortable: false
        },
        {
          text: "components.RelatorioPix.template.DataTable.IDIDEX",
          value: "transactionId",
          sortable: false
        },
        {
          text: "components.RelatorioPix.template.DataTable.Value",
          value: "valorOriginalFormatado",
          sortable: false,
          align: "right"
        },
        {
          text: "components.RelatorioPix.template.DataTable.Expiration",
          value: "expires",
          sortable: false,
          align: "right"
        },
        {
          text: "components.RelatorioPix.template.DataTable.Payment",
          value: "dataPagamento",
          sortable: false,
          align: "right",
        },
        {
          text: "components.RelatorioPix.template.DataTable.Paid",
          value: "valorPagoFormatado",
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "valor",
          sortable: false,
        },
      ],
      desserts: new Array<RelatorioPix>(),

      totalDesserts: 0,
      timeout: 0,
      startPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      endPeriod: moment().format("YYYY-MM-DDT23:59"),
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      defaultStartDate: moment().format("YYYY-MM-DD"),
      defaultEndDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      permissoes: {
        perm_pix_recebidos: false,
        perm_pix_enviados: false,
        perm_qrcode_static: false,
      },
      btnDownloadDisabled: true,
      btnDownloadLoading: false,
      webhooks: {
        webhook: {
          id: "27033002",
          createdAt: "2024-03-07 11:52:07",
          type: "COBPIX",
          createdAt_ano: "2024",
          createdAt_mes: "3",
          createdAt_date: "2024-03-07",
          webhook_project_id: "22",
          clientId: "1",
          transactionId: "63510ABD51FB1B7F",
          url: "https://financeiro.invizza.com/tests/webook.php",
          sendStatus: "2",
          sendCount: "3",
        },
        attemps: {
          count: 3,
          rows: [
            {
              id: "20737407",
              webhook: "27033002",
              sendDate: "2024-03-07 11:54:14",
              sendDate_ano: "2024",
              sendDate_mes: "3",
              sendDate_date: "2024-03-07",
              sendBody:
                '{"requestBody":{"transactionType":"RECEIVEPIX","transactionId":"63510ABD51FB1B7F","external_id":"92271b39-494d-4def-98c1-648386e38f1c","amount":1,"dateApproval":"2024-03-07 11:54:14","payerName":"JOAO FERREIRA FILHO","creditParty":{"userId":null,"email":"edilson@invizza.com","taxId":"11935552000108","name":"EDILSON MOURA DA SILVA"},"debitParty":{"account":"BCO BRADESCO S.A.","bank":"BCO BRADESCO S.A.","branch":"953","taxId":"80548105391","name":"JOAO FERREIRA FILHO"},"authentication":"E60746948202403071454A9530hA97kc"}}',
              sendHeader:
                '["Verify-Signature: t=1709823254,vsign=3cc021fce5ed983f611e8dc6e9ae7c429e07555dcc4ac580aadf53e44751c844","Content-Type: application\\/json","Authorization: Basic aW52aXp6YTppbnZpenphQDEyMzQ1Ng=="]',
              responseHttpStatus: "200",
              responseBody: null,
              executionTime: "0.606188",
            },
            {
              id: "21510690",
              webhook: "27033002",
              sendDate: "2024-03-15 15:38:42",
              sendDate_ano: "2024",
              sendDate_mes: "3",
              sendDate_date: "2024-03-15",
              sendBody:
                '{"requestBody":{"transactionType":"RECEIVEPIX","transactionId":"63510ABD51FB1B7F","external_id":"92271b39-494d-4def-98c1-648386e38f1c","amount":1,"dateApproval":"2024-03-07 11:54:14","payerName":"JOAO FERREIRA FILHO","creditParty":{"userId":null,"email":"edilson@invizza.com","taxId":"11935552000108","name":"EDILSON MOURA DA SILVA"},"debitParty":{"account":"BCO BRADESCO S.A.","bank":"BCO BRADESCO S.A.","branch":"953","taxId":"80548105391","name":"JOAO FERREIRA FILHO"},"authentication":"E60746948202403071454A9530hA97kc"}}',
              sendHeader:
                '["Verify-Signature: t=1710527922,vsign=9d1da194e1662931aa550616d7cdef5d0e5e5fc7ed23a1f8099734ff42bfaa80","Content-Type: application\\/json","Authorization: Basic aW52aXp6YTppbnZpenphQDEyMzQ1Ng=="]',
              responseHttpStatus: "200",
              responseBody: null,
              executionTime: "0.707154",
            },
            {
              id: "21510702",
              webhook: "27033002",
              sendDate: "2024-03-15 15:38:56",
              sendDate_ano: "2024",
              sendDate_mes: "3",
              sendDate_date: "2024-03-15",
              sendBody:
                '{"requestBody":{"transactionType":"RECEIVEPIX","transactionId":"63510ABD51FB1B7F","external_id":"92271b39-494d-4def-98c1-648386e38f1c","amount":1,"dateApproval":"2024-03-07 11:54:14","payerName":"JOAO FERREIRA FILHO","creditParty":{"userId":null,"email":"edilson@invizza.com","taxId":"11935552000108","name":"EDILSON MOURA DA SILVA"},"debitParty":{"account":"BCO BRADESCO S.A.","bank":"BCO BRADESCO S.A.","branch":"953","taxId":"80548105391","name":"JOAO FERREIRA FILHO"},"authentication":"E60746948202403071454A9530hA97kc"}}',
              sendHeader:
                '["Verify-Signature: t=1710527936,vsign=820376a6e6acfa63247c03fdd17d1acf42a90848e4ac7965d0052d6227bf6597","Content-Type: application\\/json","Authorization: Basic aW52aXp6YTppbnZpenphQDEyMzQ1Ng=="]',
              responseHttpStatus: "200",
              responseBody: null,
              executionTime: "0.550223",
            },
          ],
        },
      },
    };
  },
  created() {
    this.permissoes = Auth.getUserPermissoes();
    if (!this.permissoes.perm_pix_recebidos) {
      this.$router.go(-1);
    }
    this.isMobile = this.detectMobile();
    this.translateStatusPix();
    this.translateHeaders();
    this.translateQueryField();
  },

  methods: {
    translateStatusPix() {
      this.translatedStatusPix = this.statusPix.map(status => {
        const translationKey = `components.RelatorioPix.template.StatusPix.${status.descricao}`;
        return {
          id: status.id,
          descricao: this.$t(translationKey) as string
        };
      });
    },
    translateQueryField() {
      this.translatedQueryField = this.queryField.map(status => {
        const translationKey = `components.RelatorioPix.template.queryField.${status.id}`;

        return {
          id: status.id,
          descricao: this.$t(translationKey) as string
        };
      });
    },
    translateHeaders() {
      this.headers.forEach(header => {
        this.$set(this.headerTranslations, header.text, this.$t(header.text));
      });
    },
    next(page: number) {
      this.page = page;
      this.loadDatas();
    },
    getWebhooksHistory(transactionId: string) {
      Overlay.show();
      Api.get("/v2/colaboradores/webhooks/" + transactionId + "/view")
        .then((response) => {
          this.webhooks = response.data.body;
          this.dialogWebhooks = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Snackbar.show(data.body.error, "error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    resendWebhook(transactionId: string) {
      this.btnLoadingResent = true;
      Overlay.show();
      Api.get("/v2/colaboradores/webhooks/" + transactionId + "/send")
        .then((response) => {
          this.getWebhooksHistory(transactionId);
          Snackbar.show("Webhook reenviado", "success");
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Snackbar.show(data.body.error, "error");
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoadingResent = false;
          Overlay.hide();
        });
    },
    doDelayedSearch() {
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    downloadReport() {
      this.btnDownloadLoading = true;
      Overlay.show();

      Api.get("/v2/colaboradores/financeiro/pix/relatorios/recebidos", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          query: this.search,
          status: this.selectedEntryStatusPix.id,
          export: true,
          queryField: this.selectedEntryQueryField.id
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "pix_recebidos_" +
            this.selectedEntryStatusPix.id +
            "-" +
            this.startPeriod +
            "-" +
            this.endPeriod +
            ".xlsx";
          link.click();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnDownloadLoading = false;
        });
    },
    loadDatas() {
      const intervalo = Math.round(
        (new Date(this.endPeriod).getTime() -
          new Date(this.startPeriod).getTime()) /
        (1000 * 3600 * 24)
      );

      if (intervalo > 31) {
        Snackbar.show(
          "O intervalo de pesquisa não pode ser superior a 31 dias",
          "error"
        );
        return false;
      }
      this.hasLoadedData = true;
      Overlay.show();

      this.loadingData = true;

      this.loadingDesserts = true;
      Api.get("/v2/colaboradores/financeiro/pix/relatorios/recebidos", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          query: this.search,
          status: this.selectedEntryStatusPix.id,
          page: this.page,
          queryField: this.selectedEntryQueryField.id
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as RelatorioPix[];
          this.totalDesserts = Number(response.data.body.count.__total);
          this.stats = response.data.body.stats;
          this.loadingDesserts = false;
          this.loadingData = false;
          if (this.totalDesserts == 0) {
            this.btnDownloadDisabled = true;
          } else {
            this.btnDownloadDisabled = false;
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    Comprovante(id: string) {
      Api.get("/v2/colaboradores/comprovantes/pix/receive/" + id)
        .then((response) => {
          this.dadosComprovantePixRecebido = response.data.body;
          this.dialogComprovantePixRecebido = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          Snackbar.show(data.body.error, "error");
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    rowClasses(item: any) {
      // const ToDate = new Date();
      if (item.status === "PENDENTE") {
        return "";
      }
      if (item.status === "APROVADO") {
        return "success--text";
      }
      if (item.status === "DEVOLVIDO") {
        return "error--text";
      }
      if (item.status === "EXPIRADO") {
        return "error--text";
      }
      if (item.status === "INFRACTION") {
        return "error--text";
      }
    },
    async downloadComprovantePDF(id: string) {
      try {
        Overlay.show();

        await this.Comprovante(id);
        await this.$nextTick();

        const dados = this.dadosComprovantePixRecebido;

        if (!dados || !dados.valorFormatado || !dados.origem || !dados.destino) {
          throw new Error("Dados do comprovante não carregados corretamente");
        }

        const element = document.createElement('div');
        element.innerHTML = `
          <div style="font-family: Arial, sans-serif; background-color: #fff9c4; padding: 20px; max-width: 400px; margin: 0 auto;">
            <div style="text-align: center;">
              <img src="${require('@/assets/logo-text-black.png')}" style="width: 120px; margin-bottom: 20px;" />
              <h3 style="font-weight: bold; margin-top: 20px; margin-bottom: 15px;">
                ${this.$t('components.RelatorioPix.template.ReceivedTransfer')}
              </h3>
            </div>
            
            <hr style="border: 1px solid #ccc; margin: 15px 0;" />
            
            <div style="text-align: center;">
              <h2 style="margin-bottom: 10px;">R$ ${dados.valorFormatado}</h2>
              <p>${moment(dados.dataEfetivada).locale(this.lang).format("DD/MM/yyyy HH:mm:ss")}</p>
            </div>
            
            <hr style="border: 1px solid #ccc; margin: 15px 0;" />
            
            <h3 style="margin-top: 15px;">
              <span style="margin-right: 10px;">&#128181;</span>${this.$t('components.RelatorioPix.template.Origin')}
            </h3>
            <table style="width: 100%; border-collapse: collapse;">
              <tr>
                <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Name')}</td>
                <td style="text-align: right;">${dados.origem.name}</td>
              </tr>
              <tr>
                <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Document')}</td>
                <td style="text-align: right;">${dados.origem.taxId}</td>
              </tr>
              <tr>
                <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Institution')}</td>
                <td style="text-align: right;">${dados.origem.bank}</td>
              </tr>
              <tr>
                <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Agency')}</td>
                <td style="text-align: right;">${dados.origem.branch}</td>
              </tr>
              <tr>
                <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.AccountType')}</td>
                <td style="text-align: right;">${dados.origem.accountType}</td>
              </tr>
            </table>
            
            <h3 style="margin-top: 15px;">
              <span style="margin-right: 10px;">&#128182;</span>${this.$t('components.RelatorioPix.template.Destination')}
            </h3>
            <table style="width: 100%; border-collapse: collapse;">
              <tr>
                <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Name')}</td>
                <td style="text-align: right;">${dados.destino.name}</td>
              </tr>
              <tr>
                <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Document')}</td>
                <td style="text-align: right;">${dados.destino.taxId}</td>
              </tr>
            </table>
            
            <div style="text-align: center; margin-top: 20px;">
              <h3>${this.$t('components.RelatorioPix.template.Authentication')}</h3>
              <p>${dados.endToEndId}</p>
            </div>
          </div>
        `;

        const opt = {
          margin: 10,
          filename: `comprovante_pix_${id}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        await html2pdf().from(element).set(opt).save();

        console.log("PDF gerado com sucesso!");
      } catch (error) {
        console.error("Erro ao gerar PDF:", error);
        Snackbar.show("Erro ao gerar o PDF do comprovante", "error");
      } finally {
        Overlay.hide();
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      console.log("Locale changed:", this.$i18n.locale);
      this.translateStatusPix();
      this.translateHeaders();
    },
  },
  computed: {
    translatedHeaders() {
      let vm = this as any;
      return vm.headers.map((header: any) => ({
        ...header,
        text: header.text ? String(vm.$t(header.text)) : '',
      }));
    },
  },
});
